import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footer";

const NotFound =()=>{
    return(
        <>
            <Navbar />
            <h1 style={{marginTop: 60}}>404 No encontrado :(</h1>
            <Footer />
        </>
    )

}
export default NotFound
