import React, { useState, useEffect } from 'react';
import Images from '../../assets/images';
import './navbar.css';

const Navbar = ({ setActiveSection }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const toggleMenu = () => {
        setShowMenu(prevState => !prevState);
    };

    useEffect(() => {
        const handleResize = () => {
        if (window.innerWidth > 768 && showMenu) {
            setShowMenu(false);
        }
        };

        const handleScroll = () => {
        setScrolled(window.scrollY > 500);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('scroll', handleScroll);
        };
    }, [showMenu]);

    return (
        <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
            <div className="navbar-brand">
                <a href="#home">
                    <img className='logoHeader' alt="PyDay Logo" src={Images.pyconlogo} />
                </a>
                <button className="menu-icon" onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
            <ul className={`navbar-menu ${showMenu ? 'show' : ''}`}>
                <li id='home'>
                    <a href="#Home" onClick={() => { setActiveSection('Inicio'); setShowMenu(false); }}>Inicio</a>
                </li>
                <li id='schedule'>
                    <a href="#Schedule" onClick={() => { setActiveSection('Cronograma'); setShowMenu(false); }}>Cronograma</a>
                </li>
                <li id='coc'>
                    <a href="#COC" onClick={() => { setActiveSection('COC'); setShowMenu(false); }}>COC</a>
                </li>
                <li id='communities'>
                    <a href="#Communities" onClick={() => { setActiveSection('Comunidades'); setShowMenu(false); }}>Comunidades</a>
                </li>
                <li id='sponsors' className='hidden'>
                    <a href='#Sponsors' onClick={() => setShowMenu(false)}>Sponsors</a>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
