const Images = {

    pyconlogo:require('./PyCon-Logo.png'),

    pyconboHorizontal: require('./logo_negativo_horizontal-1.png'),
    pyconboLogoText: require('./logo_negativo_horizontal-3.png'),
    pyconFondoOscuro: require('./PyCon-LogoFondoOscuro.jpg'),
    pyconFondoClaro: require('./PyCon-LogoFondoClaro.jpg'),
    pyconHomeLogo: require('./Pycon-homeLogo.png'),

    christ1: require('./Christ 1.png'),
    christ2: require('./Christ 2.png'),
    moon: require('./Moon.png'),
    stars: require('./Stars.png'),
    line: require('./Line.png'),

    franja: require('./elemento.png'),

    logoPyCbba: require('./PythonCB-Oscuro Vertical.png'),
    logoPyCbbaHorizontalWhite: require('./PythonCB-Oscuro Horizontal.jpg'),
    logoPyladies: require('./Pyladies-Logo-Negativo.png'),
    logoPyladiesHorizontalWhite: require('./Pyladies_Horizontal_Blanco.jpg'),

    logoUcatecHBlanco: require('./UcatecHblanco.png'),
    logoUcatecHColor: require('./UcatecHcolor.png'),
    logoUcatecVBlanco: require('./UcatecVblanco.png'),
    logoUcatecVColor: require('./UcatecVcolor.png'),
  
    // allied comunities
    logoADC: require('./allied-communities/ADC.png'),
    logoHacklabBrickheads: require('./allied-communities/Hacklab-Brickheads.png'),
    logoNotionBolivia: require('./allied-communities/Notion-Bolivia.jpg'),
    logoMujeresTicBolivia: require('./allied-communities/MUJERES-TICS-BO.png'),
    logoR00thouse: require('./allied-communities/roothouse.png'),
    logoAdaInData: require('./allied-communities/Ada-In-Data.png'),
    logoMicrosoftEduHub: require('./allied-communities/microsoftEduHub.png'),
    logoHubIaBo: require('./allied-communities/hubBolivianoDeIA.jpeg'),
    logoScesiCbba: require('./allied-communities/SCESI-Cbba.png'),

    // organizing comunities
    logoPyladiesCbba: require('./organizers/pyladiesCbba.png'),
    logoPythonSucre: require('./organizers/pythonSucre.png'),
    logoPyladiesLP: require('./organizers/pyladieslp.png'),
    logoPythonCbba: require('./organizers/PythonCbba.png'),

    // Keynotes, speakers and workshoppers
    AdrianAcarapi: require('./Keynotes-speakers-workshoppers/Adrian Acarapi.jpeg'),
    AlfredoCallizaya: require('./Keynotes-speakers-workshoppers/Alfredo Callizaya.jpg'),
    AyarPaco: require('./Keynotes-speakers-workshoppers/Ayar Paco.jpg'),
    ClaudioA: require('./Keynotes-speakers-workshoppers/Claudio A..jpg'),
    ErnestoRico: require('./Keynotes-speakers-workshoppers/Ernesto Rico Schmidt.jpg'),
    EstebanSaavedra: require('./Keynotes-speakers-workshoppers/Esteban Saavedra L..png'),
    FernandoQuinteros: require('./Keynotes-speakers-workshoppers/Fernando Quinteros Gutierrez.jpg'),
    GabrielCasas: require('./Keynotes-speakers-workshoppers/Gabriel Casas.png'),
    JoelGonzales: require('./Keynotes-speakers-workshoppers/Joel Angel Gonzales Flores.jpg'),
    JoseAriza: require('./Keynotes-speakers-workshoppers/Jose Luis Ariza.jpg'),
    JosephMeneses: require('./Keynotes-speakers-workshoppers/Joseph Meneses.jpg'),
    KevinArroyo: require('./Keynotes-speakers-workshoppers/Kevin Arroyo.png'),
    LizethAmorraga: require('./Keynotes-speakers-workshoppers/Lizeth Shirley Amorraga Carvajal.png'),
    MarcoZambrana: require('./Keynotes-speakers-workshoppers/Marco Antonio Zambrana Quispe.jpg'),
    MartinArispe: require('./Keynotes-speakers-workshoppers/Martin Arispe Riveros.jpg'),
    NagibVallejos: require('./Keynotes-speakers-workshoppers/Nagib Luis Vallejos Mamani.jpg'),
    NoemiNahomy: require('./Keynotes-speakers-workshoppers/Noemi Nahomy.jpg'),
    PabloNoya: require('./Keynotes-speakers-workshoppers/Pablo Daniel Noya Quiroz.jpg'),
    RaquelCalderon: require('./Keynotes-speakers-workshoppers/Raquel Andrea Calderon Gallardo.png'),
    RodrigoMendez: require('./Keynotes-speakers-workshoppers/Rodrigo Mendez.jpg'),
    RudyManzaneda: require('./Keynotes-speakers-workshoppers/Rudy Manzaneda.png'),
    SergioBalderrama: require('./Keynotes-speakers-workshoppers/Sergio balderrama.png'),
    SergioValenzuela: require('./Keynotes-speakers-workshoppers/Sergio Hernán Valenzuela Cámara.jpg'),
    Victorjuchani: require('./Keynotes-speakers-workshoppers/Víctor Franco juchani.jpeg'),
}
export default Images;