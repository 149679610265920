import Images from '../assets/images';
import Icons from '../assets/icons';

const organizations = [
    {
        name: "Pylaides Cochabamba",
        logo: Images.logoPyladies,
        description: "La comunidad PyLadies Cochabamba busca promover la participación femenina en tecnología, enfocándose en el aprendizaje y uso del lenguaje Python. A través de eventos, conferencias y reuniones, impulsamos el crecimiento de una comunidad diversa de usuarios de Python en diversas áreas.",
        socials: [
            { icon: Icons.logoFacebook, link: "https://www.facebook.com/pyladiescbba" },
            { icon: Icons.logoEmail, link: "cochabamba@pyladies.com" },
            { icon: Icons.logoInstagram, link: "https://www.instagram.com/pyladiescbba" },
        ]
    },
    {
        name: "Python Sucre",
        logo: Images.logoPythonSucre,
        description: "Python Sucre es una comunidad emergente en Sucre, Bolivia, donde los entusiastas de Python se unen para aprender. Ofrecemos talleres prácticos, charlas inspiradoras y oportunidades de networking.",
        socials: [
            { icon: Icons.logoFacebook, link: "https://www.facebook.com/Python.Sucre.Bo" },
            { icon: Icons.logoEmail, link: "python.sucre@gmail.com" },
            { icon: Icons.logoInstagram, link: "https://www.instagram.com/pythonsucre/" },
        ]
    },
    {
        name: "PyLadies La Paz",
        logo: Images.logoPyladiesLP,
        description: "Buscamos fomentar la presencia de mujeres en la industria de la tecnología a través de la enseñanza y participación, la Comunidad es un espacio abierto dando participación a todos nuestros miembros.",
        socials: [
            { icon: Icons.logoFacebook, link: "https://www.facebook.com/pyladieslapaz" },
            { icon: Icons.logoEmail, link: "lp@pyladies.com" },
            { icon: Icons.logoInstagram, link: "https://www.instagram.com/pyladieslapaz" },
        ]
    },
    {
        name: "Python Cochabamba",
        logo: Images.logoPyCbba,
        description: "Python Cochabamba fomenta el uso y aprendizaje de Python en un entorno colaborativo, su misión es crear un entorno que promueva el aprendizaje y la innovación.",
        socials: [
            { icon: Icons.logoFacebook, link: "https://www.facebook.com/pythoncbba" },
            { icon: Icons.logoEmail, link: "pythoncochabamba@gmail.com" },
            { icon: Icons.logoInstagram, link: "https://www.instagram.com/pythoncbba" },
        ]
    },
];

const allies = [
    {
        name: "ADC",
        logo: Images.logoADC,
        socials: [
            { icon: Icons.logoFacebook, link: "https://www.facebook.com/profile.php?id=61555908429543" },
            { icon: Icons.logoEmail, link: "inteligencia.artificial.ds.060523@gmail.com" },
            { icon: Icons.logoInstagram, link: "https://www.instagram.com/adc_community0/" },
        ]
    },
    {
        name: "Hacklab Brickheads",
        logo: Images.logoHacklabBrickheads,
        socials: [
            { icon: Icons.logoFacebook, link: "https://www.facebook.com/HackLabBrickHeads" },
            { icon: Icons.logoEmail, link: "hacklab.brickheads@gmail.com" },
            { icon: Icons.logoInstagram, link: "https://www.instagram.com/hacklabbrickheads/" },
        ]
    },
    {
        name: "Notion Bolivia",
        logo: Images.logoNotionBolivia,
        socials: [
            { icon: Icons.logoFacebook, link: "https://www.facebook.com/notionbolivia" },
            { icon: Icons.logoEmail, link: "notionboliviaoficial@gmail.com" },
            { icon: Icons.logoInstagram, link: "https://www.instagram.com/notionbolivia/" },
        ]
    },
    {
        name: "Ada in Data",
        logo: Images.logoAdaInData,
        socials: [
            { icon: Icons.logoFacebook, link: "https://www.facebook.com/adaindatabo" },
            { icon: Icons.logoEmail, link: "adaindatacommunity@gmail.com" },
            { icon: Icons.logoInstagram, link: "https://www.instagram.com/adaindatabo/" },
        ]
    },
    {
        name: "Microsoft Education Hub",
        logo: Images.logoMicrosoftEduHub,
        socials: [
            { icon: Icons.logoFacebook, link: "https://www.facebook.com/microsoft.education.hub" },
            { icon: Icons.logoEmail, link: "meh.bolivia@gmail.com" },
            { icon: Icons.logoInstagram, link: "https://www.instagram.com/microsoft_education_hub" },
        ]
    },
    {
        name: "r00thouse HackLab La Paz",
        logo: Images.logoR00thouse,
        socials: [
            { icon: Icons.logoFacebook, link: "https://www.facebook.com/r00thouse" },
            { icon: Icons.logoEmail, link: "r00thouse@hacklab.org.bo" },
            { icon: Icons.logoInstagram, link: "https://www.instagram.com/r00thouse/" },
        ]
    },
    {
        name: "Mujeres Tic Bolivia",
        logo: Images.logoMujeresTicBolivia,
        socials: [
            { icon: Icons.logoFacebook, link: "https://www.facebook.com/MujeresTICsBO" },
            { icon: Icons.logoEmail, link: "caceresjose1929@gmail.com" },
            { icon: Icons.logoInstagram, link: "https://www.instagram.com/mujeresticsbo/" },
        ]
    },
    {
        name: "Sociedad Científica de Estudiantes de Sistemas e Informática (SCESI)",
        logo: Images.logoScesiCbba,
        socials: [
            { icon: Icons.logoFacebook, link: "https://www.facebook.com/scesi" },
            { icon: Icons.logoEmail, link: "contacto@scesi.org" },
            { icon: Icons.logoInstagram, link: "https://www.instagram.com/scesiumss?igsh=MWRybzc2ZjM0eDJtMw==" },
        ]
    },
    {
        name: "Hub Boliviano de Inteligencia Artificial",
        logo: Images.logoHubIaBo,
        socials: [
            { icon: Icons.logoFacebook, link: "https://www.facebook.com/hubia.bo" },
            { icon: Icons.logoEmail, link: "ocontreras309@gmail.com" },
        ]
    },
];

const sponsors = {
    diamante: [
        {
            name: "ADC",
            logo: Images.logoADC,
            socials: [
                { icon: Icons.logoFacebook, link: "https://www.facebook.com/profile.php?id=61555908429543" },
                { icon: Icons.logoEmail, link: "inteligencia.artificial.ds.060523@gmail.com" },
                { icon: Icons.logoInstagram, link: "https://www.instagram.com/adc_community0/" },
            ]
        }
    ],
    oro: [
        {
            name: "ADC",
            logo: Images.logoADC,
            socials: [
                { icon: Icons.logoFacebook, link: "https://www.facebook.com/profile.php?id=61555908429543" },
                { icon: Icons.logoEmail, link: "inteligencia.artificial.ds.060523@gmail.com" },
                { icon: Icons.logoInstagram, link: "https://www.instagram.com/adc_community0/" },
            ]
        },
        {
            name: "Hacklab Brickheads",
            logo: Images.logoHacklabBrickheads,
            socials: [
                { icon: Icons.logoFacebook, link: "https://www.facebook.com/HackLabBrickHeads" },
                { icon: Icons.logoEmail, link: "hacklab.brickheads@gmail.com" },
                { icon: Icons.logoInstagram, link: "https://www.instagram.com/hacklabbrickheads/" },
            ]
        },
        {
            name: "Notion Bolivia",
            logo: Images.logoNotionBolivia,
            socials: [
                { icon: Icons.logoFacebook, link: "https://www.facebook.com/notionbolivia" },
                { icon: Icons.logoEmail, link: "notionboliviaoficial@gmail.com" },
                { icon: Icons.logoInstagram, link: "https://www.instagram.com/notionbolivia/" },
            ]
        }
    ],
    plata: [
        {
            name: "ADC",
            logo: Images.logoADC,
            socials: [
                { icon: Icons.logoFacebook, link: "https://www.facebook.com/profile.php?id=61555908429543" },
                { icon: Icons.logoEmail, link: "inteligencia.artificial.ds.060523@gmail.com" },
                { icon: Icons.logoInstagram, link: "https://www.instagram.com/adc_community0/" },
            ]
        },
        {
            name: "Notion Bolivia",
            logo: Images.logoNotionBolivia,
            socials: [
                { icon: Icons.logoFacebook, link: "https://www.facebook.com/notionbolivia" },
                { icon: Icons.logoEmail, link: "notionboliviaoficial@gmail.com" },
                { icon: Icons.logoInstagram, link: "https://www.instagram.com/notionbolivia/" },
            ]
        }
    ]
}

const RRSS = {
    facebook: { laber: '@pyconbolivia', url: 'https://www.facebook.com/pyconbolivia' },
    email: { laber: 'pycon.bo@gmail.com', url: 'mailto: pycon.bo@gmail.com' },
    instagram: { laber: '@pyconbolivia', url: 'https://www.instagram.com/pyconbolivia/' },
    youtube: { laber: 'PyCon Bolivia', url: 'https://www.youtube.com/@PyConBolivia' },
    linkedin: { laber: 'PyCon Bolivia', url: 'https://www.linkedin.com/company/pyconbolivia/posts/' },
    tiktok: { laber: '@pyconbolivia', url: 'https://www.tiktok.com/@pyconbolivia' },
    facebookUcatec: { laber: 'Universidad UCATEC', url: 'https://www.facebook.com/UNIVERSIDAD.UCATEC' },
    instagramUcatec: { laber: '@ucatec', url: 'https://www.instagram.com/ucatec/' },
    paginaUcatec: { laber: 'Universidad UCATEC', url: 'https://ucatec.edu.bo/' },
    ubiUcatec: { laber: 'Ubicación UCATEC', url: 'https://maps.app.goo.gl/uUedWCiVDtTfF8r67' },
    organizations,
    allies,
    sponsors,
}

export default RRSS;