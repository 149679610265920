import React from 'react';
import './cardStructure.css';

const CardStructure = ({ title, color, icon, buttonText, listItems }) => {
  return (
    <div className="card" style={{ borderColor: color }}>
      <div className="card-icon-container">
        <img src={icon} alt={`${title} icon`} className="card-icon" />
      </div>
      <h4 style={{ color }}>{title}</h4>
      <ul className="card-details">
        {listItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <div className="button-container">
        <button
          className="card-button"
          style={{
            color: color,
            borderColor: color,
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = color;
            e.target.style.color = "black";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "transparent";
            e.target.style.color = color;
          }}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default CardStructure;
