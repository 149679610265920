import React, { useState } from 'react'
import ScheduleItem from './ScheduleItem';
import Images from '../../../../assets/images';
import './schedule.css';

const Schedule = () => {
    const [activeDayTab, setActiveDayTab] = useState('primero');
    const [activeRoomTab, setActiveRoomTab] = useState('salon1');

    const handleDayTabClick = (tabName) => {
        setActiveDayTab(tabName);
        setActiveRoomTab('salon1');
    };

    const handleRoomTabClick = (roomName) => {
        setActiveRoomTab(roomName);
    };

    return (
        <section className='div_schedule'>
            <div>
                {/* TAB DÍAS */}
                <div className='tabDay'>
                    <p className={activeDayTab === 'primero' ? 'tablinks active' : 'tablinks'}
                        onClick={() => handleDayTabClick('primero')}>DÍA 1 - 13 de Diciembre</p>
                    <p className={activeDayTab === 'segundo' ? 'tablinks active' : 'tablinks'}
                        onClick={() => handleDayTabClick('segundo')}>DÍA 2 - 14 de Diciembre</p>
                </div>

                {/* DÍA 1 */}
                <div id='primero' className={activeDayTab === 'primero' ? 'tabcontent active' : 'tabcontent'}>
                    {/* TAB SALÓN */}
                    <div className='tabSalon'>
                        <p className={activeRoomTab === 'salon1' ? 'button tablinks active' : 'button tablinks'}
                            onClick={() => handleRoomTabClick('salon1')}>Salón 1</p>
                        <p className={activeRoomTab === 'salon2' ? 'button tablinks active' : 'button tablinks'}
                            onClick={() => handleRoomTabClick('salon2')}>Salón 2</p>
                    </div>

                    {/* Salón 1 */}
                    {activeRoomTab === 'salon1' && (
                        <div>
                            <ScheduleItem
                                time="09:00 - 09:10"
                                image={Images.pyconFondoClaro}
                                type="Apertura"
                                title="Acreditación al evento"
                                name="Organizadores de Pycon Bolivia 2024"
                                description="¡Iniciamos la Pycon Bolivia 2024 en Cochabamba agradeciendo a todos los participantes, speakers, workshoppers y sponsors del evento!"
                            />
                            <ScheduleItem
                                time="09:10 - 09:45"
                                image={Images.KevinArroyo}
                                type="Speaker"
                                title="Diseño y Desarrollo de Aplicaciones Multiplataforma con Flet "
                                name="Kevin Omar Arroyo Montaño"
                                description="Mi charla abordará el diseño y desarrollo de aplicaciones multiplataforma utilizando Flet y Python, destacando cómo estas herramientas permiten crear interfaces gráficas modernas y eficientes con una base de código unificada. Presentaré ejemplos prácticos y casos de uso, mostrando cómo integrar funcionalidades avanzadas y optimizar la experiencia de usuario en diferentes dispositivos. Además, discutiré las ventajas de usar Python como lenguaje versátil en el desarrollo ágil de aplicaciones."
                            />
                            <ScheduleItem
                                time="09:50 - 10:25"
                                image={Images.RudyManzaneda}
                                type="Speaker"
                                title="Scikit-learn en Acción: Aplicación Práctica de Algoritmos de Machine Learning para la Limpieza y Modelado de Datos"
                                name="Rudy Luis Manzaneda Veizaga"
                                description="En esta charla se explorará cómo Scikit-learn puede ser utilizado de manera efectiva para el tratamiento de datos y la creación de modelos de machine learning. A través de ejemplos prácticos, se cubrira todo el ciclo de vida de un proyecto de machine learning basado en la metodología CRISP-DM: desde la limpieza y preparación de datos, manejo de valores faltantes y categóricos, hasta la implementación de modelos como regresión lineal y Random Forest."
                            />
                            <ScheduleItem
                                time="10:30 - 11:20"
                                image={Images.MartinArispe}
                                type="Speaker"
                                title="Pair programming with LLM's using Python"
                                name="Martin Arispe Riveros"
                                description="You will learn how to develop a web service based on Python making pair programming techniques with Gemini and chatGPT within a Google project IDX environment."
                            />
                            <ScheduleItem
                                time="11:25 - 12:00"
                                image={Images.JoseAriza}
                                type="Speaker"
                                title="El zen de Python - más allá de un easter egg"
                                name="Jose Luis Ariza Cabrera"
                                description="Todos conocemos el clásico “import this” . Es una forma de sorprender a los junior developers y hacer uno que otro chiste al respecto. Sin embargo, ¿cuánto del zen de python realmente conocemos y aplicamos? ¿Cuántos de sus aforismos entendemos a cabalidad? En esta charla, iremos un poco más allá del easter egg y revisaremos y ejemplificaremos qué es lo que el zen de python realmente nos quiere decir."
                            />
                            <ScheduleItem
                                time="14:00 - 14:35"
                                image={Images.FernandoQuinteros}
                                type="Speaker"
                                title="Transforma Tus Proyectos con la API de OpenAI"
                                name="Fernando Quinteros Gutierrez"
                                description="En esta charla, exploraremos cómo integrar la API de OpenAI en tus proyectos con Python para aprovechar el poder de la IA generativa. Aprenderás a configurar el entorno necesario, así como a formular prompts efectivos para obtener los mejores resultados para proyectos de desarrollo de software."
                            />
                            <ScheduleItem
                                time="14:40 - 15:15"
                                image={Images.GabrielCasas}
                                type="Speaker"
                                title="¡Encuéntrame! "
                                name="Gabriel Casas Mamani"
                                description="Es una app que permite buscar similitud entre mascotas(perros) que se encuentran perdidas. El objetivo es ayudar a encontrar de manera más rápida a una mascota perdida usando Modelos de Deep Learning, la aplicación está desarrollada con Flet, y los datos para entrenar corresponden a Stanford Dogs Dataset. Durante la exposición se mostrará de manera práctica el modelo de Deep Learning usados para la clasificación. Posteriormente se mostrará las bondades de Flet como framework multiplataforma."
                            />
                            <ScheduleItem
                                time="15:20 - 16:10"
                                image={Images.SergioValenzuela}
                                type="Speaker"
                                title="Mental-ia"
                                name="Sergio Hernán Valenzuela Cámara"
                                description="Mental-ia: Aplicaciones web, Inteligencia artificial (IA), Procesamiento de Lenguaje Natural"
                            />
                            <ScheduleItem
                                time="16:25 - 17:00"
                                image={Images.NoemiNahomy}
                                type="Speaker"
                                title="Performance en python caso tasking-mananger de hotosm  "
                                name="Noemi Ticona"
                                description="El performance en la aplicaciones ya  posicionadas es de vital importancia para brindar un buen servicio."
                            />
                            <ScheduleItem
                                time="17:15 - 17:30"
                                image={Images.pyconFondoClaro}
                                type="Clausura"
                                title="Cierre de primer día del Evento"
                                name="Organizadores de Pycon Bolivia 2024"
                                description="¡Daremos fin a una excelente y diveretida jornada de aprendizaje para todos los pythonistas en el evento. Agradeceremos a todos los miembros y sponsors que hicieron posible el evento y nos tomaremos fotos para el recuerdo!"
                            />
                        </div>
                    )}

                    {/* Salón 2 */}
                    {activeRoomTab === 'salon2' && (
                        <div>
                            <ScheduleItem
                                time="09:25 - 10:25"
                                image={Images.JosephMeneses}
                                type="Tallerista"
                                title="CodeMapX: Tu Asistente IA para Documentación y Diagramas en Python"
                                name="Joseph Anthony Meneses Salguero"
                                description="En esta charla presentare CodeMapX, una herramienta desarrollada por mí, diseñada para automatizar la generación de documentación técnica y diagramas de arquitectura en proyectos de Python y otros lenguajes en el futuro. CodeMapX ayuda a los desarrolladores a crear documentación detallada de sus proyectos de manera eficiente, integrando diagramas visuales que muestran las interacciones entre funciones y servicios externos, todo de forma automática."
                            />
                            <ScheduleItem
                                time="10:30 - 11:20"
                                image={Images.AdrianAcarapi}
                                type="Speaker"
                                title="De Estrellas a Algoritmos:Introduccion al Machine Learning en la Astronomia Computacional"
                                name="Adrian Fernando Acarapi Roca"
                                description="Los procesadores han avanzado enormemente, permitiendo manejar grandes volúmenes de datos, como los generados en astronomía con telescopios terrestres y espaciales. Python es fundamental en este proceso, ya que con sus bibliotecas es posible transformar y analizar estos datos mediante algoritmos complejos de Machine Learning. Esto ha democratizado el acceso a los datos astronómicos, permitiendo a cualquier persona investigarlos y generar conocimiento valioso."
                            />
                            {/* <ScheduleItem
                                time="11:25 - 11:40"
                                image={Images.pyconFondoClaro}
                                type="Charla Comunidad"
                                title="Título de charla"
                                name="Speaker por designar"
                                description="Descripción de la charla"
                            /> */}
                            <ScheduleItem
                                time="14:00 - 15:00"
                                image={Images.NagibVallejos}
                                type="Tallerista"
                                title="Robot móvil controlado por WiFi"
                                name="Nagib Luis Vallejos Mamani"
                                description="Control de un robot movil mendiante esp32 o también Raspberry pi (pico o pico w) a través de WiFi y empleando la plataforma de Blynk IoT para la comunicación."
                            />
                            {/* <ScheduleItem
                                time="14:40 - 15:15"
                                image={Images.pyconFondoClaro}
                                type="Speaker"
                                title="Título de charla"
                                name="Speaker por designar"
                                description="Descripción de la charla"
                            /> */}
                            <ScheduleItem
                                time="16:25 - 17:00"
                                image={Images.RodrigoMendez}
                                type="Charla"
                                title="Python: El motor detrás de la inteligencia conversacional de ChatGPT"
                                name="Rodrigo Camilo Mendez Gallardo "
                                description="Mi charla explorará cómo Python impulsa herramientas de IA como ChatGPT, desde el procesamiento del lenguaje natural hasta la creación de modelos de aprendizaje profundo. Abordaré conceptos básicos de Python en inteligencia artificial, frameworks como PyTorch y técnicas de modelado de lenguaje, simplificando la teoría para hacerla accesible al público general. La charla estará diseñada para inspirar y despertar curiosidad en la inteligencia conversacional."
                            />
                        </div>
                    )}
                </div>

                {/* DIA 2 */}
                <div id='segundo' className={activeDayTab === 'segundo' ? 'tabcontent active' : 'tabcontent'}>
                    {/* TAB SALÓN */}
                    <div className='tabSalon'>
                        <p className={activeRoomTab === 'salon1' ? 'button tablinks active' : 'button tablinks'}
                            onClick={() => handleRoomTabClick('salon1')}>Salón 1</p>
                        <p className={activeRoomTab === 'salon2' ? 'button tablinks active' : 'button tablinks'}
                            onClick={() => handleRoomTabClick('salon2')}>Salón 2</p>
                    </div>

                    {/* Salón 1 */}
                    {activeRoomTab === 'salon1' && (
                        <div>
                            <ScheduleItem
                                time="09:00 - 09:10"
                                image={Images.pyconFondoClaro}
                                type="Apertura"
                                title="Acreditación al evento"
                                name="Organizadores de Pycon Bolivia 2024"
                                description="¡Iniciamos la Pycon Bolivia 2024 en Cochabamba agradeciendo a todos los participantes, speakers, workshoppers y sponsors del evento!"
                            />
                            <ScheduleItem
                                time="09:10 - 09:45"
                                image={Images.JoelGonzales}
                                type="Speaker"
                                title="Python y EEG"
                                name="Joel Angel Gonzales Flores"
                                description="Exploraremos cómo se utilizará Python para el análisis de señales EEG desde las neurociencias cognitivas y como en NeurotechUMSA ha sido muy util.  Usaremos bibliotecas disponibles en google colab. Ademas abordaremos como python es fundamental para la limpieza de datos de EEG y su visualización."
                            />
                            <ScheduleItem
                                time="09:50 - 10:25"
                                image={Images.SergioBalderrama}
                                type="Speaker"
                                title="Python para investigación científica: una aplicación practica en modelación energética en Bolivia"
                                name="Sergio Balderrama"
                                description="Uno de los usos más extendidos de Python es dentro del área científica como herramienta para lograr realizar cálculos complejos. Para esto se han desarrollado una serie de softwares y librerías generales y de manera específica. En esta charla se enfocará en presentar las practicas más comunes para lograr integrar Python dentro de una investigación. Adicionalmente, se mostrará un caso práctico en el cual se implementó Python para realizar un modelo de planificación energética."
                            />
                            <ScheduleItem
                                time="10:30 - 11:20"
                                image={Images.ErnestoRico}
                                type="Speaker"
                                title="Catalogando y organizando una biblioteca personal con Django."
                                name="Ernesto Rico Schmidt"
                                description="`biblioteca` es un proyecto Web con Django para catalogar y ordenar los libros de la biblioteca personal de mi padre. La charla describe los modelos de que fueron creados para empezar, algunas de las decisiones y los ajustes que se hicieron y el uso de la interfaz de administración de Django. El resto de la charla se enfoca en los modelos de datos para los estantes y la ubicación de los libros y el desarrollo de una interfaz simple de búsqueda."
                            />
                            <ScheduleItem
                                time="11:25 - 12:00"
                                image={Images.EstebanSaavedra}
                                type="Speaker"
                                title="IPFS + Python: La Unión perfecta para la gestión de datos descentralizados"
                                name="Esteban Saavedra Lopez"
                                description="Los IPFS desafían la centralización de internet, reduciendo la dependencia de servidores y proveedores de servicios únicos, convirtiéndose en la tendencia de red de archivos distribuidos y descentralizados, diseñados para almacenar, compartir datos de forma más eficiente y segura, desarrollo de aplicaciones descentralizadas (dApps) y el almacenamiento a largo plazo. Esta conferencia pretende mostrar las posibilidades que brinda Python, como la herramienta perfecta para este tipo de soluciones."
                            />
                            <ScheduleItem
                                time="14:00 - 14:35"
                                image={Images.RaquelCalderon}
                                type="Speaker"
                                title="Navegando el ADN: Análisis Genómico con Biopython"
                                name="Raquel Andrea Calderon Gallardo"
                                description="Durante la charla, abordaremos los fundamentos de la bioinformática y conceptos clave, seguido de una exploración de Biopython como herramienta poderosa para procesar y analizar datos biológicos. Veremos cómo utilizar Biopython para realizar alineamientos y visualizar resultados, destacando su aplicabilidad en la investigación biológica y médica, así como en el desarrollo de soluciones innovadoras para problemas de salud pública."
                            />
                            <ScheduleItem
                                time="14:40 - 15:15"
                                image={Images.PabloNoya}
                                type="Speaker"
                                title="Desarrollo y despliegue de Data Apps con Streamlit"
                                name="Pablo Daniel Noya Quiroz"
                                description="Streamlit es un framework para escribir aplicaciones web orientadas a datos. A través de un caso práctico utilizando el Iris dataset, aprenderemos a crear y desplegar un modelo de ML. Este enfoque nos permite compartir los resultados sin necesitar conceptos avanzados en desarrollo web, ¡y escribiendo sólo Python!"
                            />
                            <ScheduleItem
                                time="15:20 - 16:10"
                                image={Images.Victorjuchani}
                                type="Speaker"
                                title="Integración de Protocolos Industriales con ESP32 y MicroPython"
                                name="Victor Franco Juchani Gallego"
                                description="En esta charla, Se explicara cómo los protocolos industriales pueden ser implementados en plataformas embebidas de bajo costo como el ESP32, utilizando MicroPython. La idea es demostrar cómo estas tecnologías accesibles pueden ser parte de soluciones industriales reales."
                            />
                            <ScheduleItem
                                time="16:25 - 17:00"
                                image={Images.pyconFondoClaro}
                                type="Speaker"
                                title="Introducción a Big Data con PySpark: Procesamiento Rápido y Eficiente"
                                name="Jose Gabriel Mita Rodriguez"
                                description="En esta charla exploraremos cómo PySpark, la interfaz de Python para Apache Spark, es esencial para procesar grandes volúmenes de datos en entornos distribuidos. Los asistentes aprenderán a utilizar PySpark para trabajar con Big Data, descubriendo componentes clave como DataFrames y Spark SQL. Presentaremos ejemplos prácticos que demuestran su capacidad para manejar datos masivos, ofreciendo una solución escalable y accesible para analistas y desarrolladores que buscan avanzar más allá de Pandas"
                            />
                            <ScheduleItem
                                time="17:15 - 17:30"
                                image={Images.pyconFondoClaro}
                                type="Clausura"
                                title="Cierre de primer día del Evento"
                                name="Organizadores de Pycon Bolivia 2024"
                                description="¡Daremos fin a una excelente y diveretida jornada de aprendizaje para todos los pythonistas en el evento. Agradeceremos a todos los miembros y sponsors que hicieron posible el evento y nos tomaremos fotos para el recuerdo!"
                            />
                        </div>
                    )}

                    {/* Salón 2 */}
                    {activeRoomTab === 'salon2' && (
                        <div>
                            <ScheduleItem
                                time="09:25 - 10:25"
                                image={Images.LizethAmorraga}
                                type="Tallerista"
                                title="Procesamiento de Lenguaje Natural en Acción: Crea tu primer Chatbot "
                                name="Lizeth Shirley Amorraga Carvajal"
                                description="Los participantes aprenderán a definir reglas gramaticales que permitirán al chatbot interpretar y responder a entradas de texto de manera más efectiva. Se abordarán temas de Gramática Independiente de Contexto, brindando así una base sólida para el desarrollo de sistemas de Procesamiento de Lenguaje Natural más sofisticados. Para esto se realizará una actividad tipo dinámica. Luego crearemos un chatbot con NLTK."
                            />
                            <ScheduleItem
                                time="10:30 - 11:20"
                                image={Images.ClaudioA}
                                type="Speaker"
                                title="Simplificando la Integración con OpenAI API: Structured Outputs y Function Calling en Python"
                                name="Claudio Ayma Ovando"
                                description="Se explorara cómo utilizar la API de OpenAI de manera eficiente y sencilla para trabajar con structured outputs y function calling en Python. Mostraremos cómo integrar modelos de datos usando Pydantic o BaseModel para facilitar la validación y el manejo de respuestas estructuradas, permitiendo que las aplicaciones sean más robustas y eficientes."
                            />
                            <ScheduleItem
                                time="11:25 - 11:40"
                                image={Images.AyarPaco}
                                type="Charla Comunidad"
                                title="Desarrolla flujos de machine learning usando Kedro"
                                name="Ayar Yuman Paco Sanizo"
                                description="Describiré las ventajas que tiene el uso de Kedro a la hora de desarrollar flujos de machine learning. Partiré describiendo algunas malas prácticas y problemas comunes a la hora de desarrollar flujos de machine learning. Posteriormente, resumiré las ventajas que tiene Kedro para afrontar las malas prácticas y problemas comunes que se describieron. Finalmente, mostraré una demo para mostrar las bondades de Kedro de forma práctica."
                            />
                            {/* <ScheduleItem
                                time="14:00 - 14:35"
                                image={Images.pyconFondoClaro}
                                type="Speaker"
                                title="Título de charla"
                                name="Speaker por designar"
                                description="Descripción de la charla"
                            />
                            <ScheduleItem
                                time="14:40 - 15:15"
                                image={Images.pyconFondoClaro}
                                type="Speaker"
                                title="Título de charla"
                                name="Speaker por designar"
                                description="Descripción de la charla"
                            />
                            <ScheduleItem
                                time="16:25 - 17:00"
                                image={Images.pyconFondoClaro}
                                type="Tallerista"
                                title="Título de charla"
                                name="Speaker por designar"
                                description="Descripción de la charla"
                            /> */}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}

export default Schedule;
