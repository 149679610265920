import React from "react";
import ConductItem from "./conductItem";
import './codeOfConduct.css';

const CodeOfConduct =()=>{
    return (
        <>
            <section className='div_COC'>
                {/* <div className='title'>
                    <text>Código de Conducta</text>
                </div> */}
                <div>
                    <ConductItem
                        title="Para Asistentes"
                        content="PyCon Bolivia es un evento inclusivo y seguro para todos los participantes. Como asistente, te pedimos que sigas estas reglas:"
                        points={[
                            "Trata a todos los participantes con respeto y cortesía, independientemente de su identidad, género, orientación sexual, habilidad, apariencia física, religión o cualquier otra característica personal.",
                            "Respeta el espacio y las opiniones de los demás. No toleramos el acoso ni la discriminación en ninguna forma.",
                            "Mantén un comportamiento adecuado en todo momento durante el evento y las actividades relacionadas.",
                            "Si te has registrado para el evento, por favor comprométete a asistir. La liberación de tu cupo en caso de no poder asistir permitirá a otros interesados disfrutar de esta experiencia.",
                            "Los asistentes que no se han registrado previamente podrán tener la oportunidad de ingresar al evento en función de los espacios disponibles debido a la ausencia de asistentes registrados. Los horarios y la disponibilidad de estos espacios se gestionarán exclusivamente por el staff del evento.",
                            "Es crucial llegar a tiempo. Si llegas tarde, podrías perder los beneficios asociados. A partir de horarios establecidos se habilitarán espacios para asistentes no registrados en función de las ausencias de personas registradas. Por favor, llegar temprano para asegurar tu lugar y aprovechar al máximo el evento.",
                            "Si experimentas o presencias cualquier forma de acoso, te alentamos a que lo informes a los organizadores de PyCon Bolivia inmediatamente."
                        ]}
                    />
                    <ConductItem
                        title="Para Speakers y Talleristas"
                        content="Como speaker o tallerista, tu contribución es fundamental para el éxito de PyCon Bolivia. Te pedimos que cumplas con las siguientes pautas:"
                        points={[
                            "Proporciona una presentación o taller respetuoso y libre de contenido ofensivo o discriminatorio.",
                            "Fomenta un entorno inclusivo donde todos los participantes se sientan bienvenidos y valorados.",
                            "Respeta el tiempo asignado para tu presentación o taller y permite la participación de la audiencia cuando sea adecuado.",
                            "Evita cualquier tipo de conducta inapropiada durante tu participación en PyCon Bolivia 2024."
                        ]}
                    />
                    <ConductItem
                        title="Para Staffs"
                        content="Como miembro del staff de PyCon Bolivia, tienes la responsabilidad de mantener un ambiente seguro y acogedor para todos los participantes. Por favor, sigue estas pautas:"
                        points={[
                            "Sé un ejemplo de comportamiento respetuoso y profesional en todo momento.",
                            "Escucha y atiende las preocupaciones de los participantes de manera justa y diligente.",
                            "Actúa con prontitud y decisión en caso de presenciar o recibir informes de cualquier forma de acoso o comportamiento inapropiado.",
                            "Respeta la privacidad y la confidencialidad de cualquier información sensible que puedas manejar como parte del equipo de PyCon Bolivia."
                        ]}
                    />
                    <ConductItem
                        title="Consecuencias por Incumplimiento"
                        content="Consecuencias por Incumplimiento como asistente, Speakers/Talleristas y miembro del staff:"
                        points={[
                            "Advertencia verbal en primer instancia.",
                            "Si un Asistente incurre en reincidencia o incumplimiento grave se procedera a la expulsión del evento.",
                            "Si un Speakers/Talleristas incurre en reincidencia o incumplimiento grave se procedera al retiro de la participación en el evento y posible prohibición de futuras presentaciones en eventos organizados por PyCon Bolivia.",
                            "Si un miembro del Staff incurre en reincidencia se procedera a darle una suspensión temporal de las responsabilidades como miembro del staff.",
                            "Si un miembro del Staff incurre en algún incumplimiento grave se procedera a la sesvinculación del equipo de PyCon Bolivia 2024.",
                            "Si eres victima o testigo de actos que vayan en contra del código de conducta, puedes reportalo a los miembros del Staff o al correo: pycon.bo@gmail.com"

                        ]}
                    />
                </div>
            </section>
        </>
    )

}
export default CodeOfConduct;
