import React, { useEffect, useState } from 'react';
import RRSS from '../../../../constants';
import './communities.css';

const Communities = () => {
    const [randomOrganizations, setRandomOrganizations] = useState([]);
    const [randomAllies, setRandomAllies] = useState([]);

    useEffect(() => {
        setRandomOrganizations([...RRSS.organizations].sort(() => Math.random() - 0.5));
        setRandomAllies([...RRSS.allies].sort(() => Math.random() - 0.5));
    }, []);

    return (
        <section>
            <div className='div_Communities'>
                <h1>Organizadoras</h1>
                <div className="container-communities">
                    {randomOrganizations.map((org, index) => (
                        <div key={index} className={`container-community ${index % 2 === 0 ? 'left' : 'right'}`}>
                            <div className="logo-social-container">
                                <img className="community-logo" src={org.logo} alt={org.name} />
                                <div className="social-links">
                                    {org.socials.map((social, idx) => (
                                        <a key={idx} href={social.link} target="_blank" rel="noreferrer">
                                            <img src={social.icon} alt="" />
                                        </a>
                                    ))}
                                </div>
                            </div>
                            <div className="community-data">
                                <p>{org.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <h1>Aliadas</h1>
                <div className="container-allies">
                    {randomAllies.map((ally, index) => (
                        <div key={index} className="ally-item">
                            <img className="ally-logo" src={ally.logo} alt={ally.name} />
                            <div className="ally-social-links">
                                {ally.socials.map((social, idx) => (
                                    <a key={idx} href={social.link} target="_blank" rel="noreferrer">
                                        <img src={social.icon} alt="" />
                                    </a>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Communities;
