import React, { useState, useEffect } from 'react';
import Icons from "../../assets/icons";
import Images from "../../assets/images";
import RRSS from "../../constants";
import "./footer.css";

const Footer = ({ setActiveSection }) => {
  return (
    <>
      <div>
        <hr className="footer-line"/>
      </div>
      <footer className="footer" id="Contact">
        <div className="footer-container">
          <img src={Images.pyconboHorizontal} alt="PyCon Bolivia Logo" className="footer-logo" />
          <nav className="footer-nav">
            <a href="#Home" className="footer-link" onClick={() => { setActiveSection('Inicio')}}>Inicio</a>
            <a href="#Schedule" className="footer-link" onClick={() => { setActiveSection('Cronograma')}}>Cronograma</a>
            <a href="#COC" className="footer-link" onClick={() => { setActiveSection('COC')}}>Código de Conducta</a>
            <a href="#Communities" className="footer-link" onClick={() => { setActiveSection('Comunidades') }}>Communities</a>
            {/* <a href="#Sponsors" className="footer-link">Sponsors</a> */}
          </nav>
          <div className="footer-icons">
            <a href={RRSS.facebook.url} target="_blank" rel="noreferrer">
              <img src={Icons.logoFacebook} alt="Facebook" className="social-icon" />
            </a>
            <a href={RRSS.instagram.url} target="_blank" rel="noreferrer">
              <img src={Icons.logoInstagram} alt="Instagram" className="social-icon" />
            </a>
            <a href={RRSS.email.url} target="_blank" rel="noreferrer">
              <img src={Icons.logoEmail} alt="Email" className="social-icon" />
            </a>
            <a href={RRSS.youtube.url} target="_blank" rel="noreferrer">
              <img src={Icons.lodoYoutube} alt="YouTube" className="social-icon" />
            </a>
            <a href={RRSS.linkedin.url} target="_blank" rel="noreferrer">
              <img src={Icons.logoLinkedIn} alt="LinkedIn" className="social-icon" />
            </a>
            <a href={RRSS.tiktok.url} target="_blank" rel="noreferrer">
              <img src={Icons.logoTiktok} alt="TikTok" className="social-icon" />
            </a>
          </div>
          <div className="footer-social-handle">
            <span>@pyconbolivia</span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
