const Icons = {
    logoFacebook: require('./logo-facebook.png'),
    logoEmail: require('./logo-email.png'),
    logoInstagram: require('./logo-instagram.png'),
    lodoYoutube: require('./logo-youtube.png'),
    logoLinkedIn: require('./logo-linkedIn.png'),
    logoTiktok: require('./logo-tiktok.png'),
    logoCalendar: require('./logo-calendar.png'),
    logoClock: require('./logo-clock.png'),
    logoLocations: require("./logo-locations.png"),
    logoBuilding: require('./logo-building.png'),
    iconYellowArrow: require("./yellow-arrow.png"),
    iconPinkArrow: require("./pink-arrow.png"),
    iconBronce: require("./sponsorsCards/bronze.png"),
    iconDiamond: require("./sponsorsCards/diamond.png"),
    iconGold: require("./sponsorsCards/gold.png"),
    iconSilver: require("./sponsorsCards/silver.png"),
}
export default Icons;