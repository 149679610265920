import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './views/Home';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import CodeOfConduct from "./views/CodeOfConduct";
import Communities from "./views/Communities";
import Schedule from "./views/Schedule";
import NotFound from "./views/NotFound";
// import Sponsors from './views/sponsors';

import './styles/index.css';


const App = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/CodeOfConduct",
        element: <CodeOfConduct/>,
    },
    {
        path: "/Communities",
        element: <Communities/>,
    },
    {
        path: "/Schedule",
        element: <Schedule/>,
    },
    {
        path: "*",
        element: <NotFound/>,
    }
]);
ReactDOM.createRoot( document.getElementById('root')).render(
    <React.StrictMode>
        <RouterProvider router={App} />
    </React.StrictMode>
)
export default App
