import React from 'react';
import CardStructure from './cardStructure';
import './cardsForSponsor.css';
import Icons from '../../../../assets/icons';

const CallForSpeaker = () => {
  return (
    <>
      <section id="sponsors-packages">
        <h2>Sponsors</h2>
        <div className="cards-container">
          <CardStructure 
            title="Sponsor Bronce" 
            color="#e59866" 
            buttonText="Únete" 
            buttonColor="#e59866" 
            icon={Icons.iconBronce} 
            listItems={[
              "✔ Logo en la página oficial de la conferencia web",
              "✔ Enlace a la página web del sponsor",
              "✔ Post de agradecimientos en redes sociales",
              "✔ Mención de agradecimiento durante la conferencia",
              "✔ Logo en el reverso del folder conmemorativo oficial",
            ]}   
          />
          <CardStructure 
            title="Sponsor Plata" 
            color="#c0c0c0" 
            buttonText="Únete" 
            buttonColor="#c0c0c0" 
            icon={Icons.iconSilver} 
            listItems={[
              "✔ Todos los beneficios de Bronce",
              "✔ 2 Entradas al evento",
              "✔ Logo en las credencial",
              "✔ Logo destacable en afiches oficiales",          
            ]}
          />
          <CardStructure 
            title="Sponsor Oro" 
            color="#ffd700" 
            buttonText="Únete" 
            buttonColor="#ffd700" 
            icon={Icons.iconGold} 
            listItems={[
              "✔ Todos los beneficios de Plata",
              "✔ 5 Entradas al evento",
              "✔ Espacio para un Stand dentro de la conferencia",
              "✔ Espacio para Networking con los participantes",
              "✔ Recepción y entrega de souvenirs del sponsor",
            ]}
          />
          <CardStructure 
            title="Sponsor Diamante" 
            color="#3498db" 
            buttonText="Únete" 
            buttonColor="#3498db" 
            icon={Icons.iconDiamond} 
            listItems={[
              "✔ Todos los beneficios de Oro",
              "✔ 10 Entradas al evento",
              "✔ Espacio de 15 minutos para una presentación empresarial",
              "✔ Espacio para difusión y publicación de ofertas laborales",        
            ]}
          />
        </div>
      </section>
    </>
  );
};

export default CallForSpeaker;
