import Navbar from "../../components/Navbar";
import Footer from "../../components/footer";
import React from "react";

const Schedule =()=>{
    return(
        <>
            <Navbar />
            <h1 style={{marginTop: 60}}>Aqui Schedule</h1>
            <Footer />
        </>
    )

}
export default Schedule;
