import React from 'react';
import './codeOfConduct.css';

const ConductItem = ({ title, content, type, points }) => {
  return (
    <div className={`conduct-item ${type}`}>
        <div className='subtitle'>
            <text>{title}</text>
        </div>
        <div className='text'>
            <text>{content}</text>
        </div>
      <ul>
        {points.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>
    </div>
  );
};

export default ConductItem;

