import React, { useState } from 'react';
import Navbar from '../../components/Navbar';
import Images from '../../assets/images';
import Footer from '../../components/footer';
import Schedule from './sections/Schedule';
import Communities from './sections/Communities';
// import Sponsors from './sections/sponsors';
import Campus from './sections/Campus';
import Countdown from '../../components/Countdown';
import Icons from '../../assets/icons';
import CodeOfConduct from "../CodeOfConduct";
import CallForSponsor from "./sections/CallForSponsor";

const Home = () => {
	const targetDate = new Date("2024-12-13T08:59:59");
	const [activeSection, setActiveSection] = useState('Inicio');
	const isScheduleOrCOCOrCommunitiesActive = activeSection === 'Cronograma' || activeSection === 'COC' || activeSection === 'Comunidades';
	
	return (
		<div>
			<div className='mainContainer' style={{ height: isScheduleOrCOCOrCommunitiesActive ? '65dvh' : '100dvh' }}>
				<Navbar setActiveSection={setActiveSection} /> 
				<div id='background' style={{ height: isScheduleOrCOCOrCommunitiesActive ? '65dvh' : '100dvh' }}>
					<div className='BackgroundElements'  style={{ height: isScheduleOrCOCOrCommunitiesActive ? '65dvh' : '100dvh' }}>
						<div className='BackgroundElements-stars'>
							<img id='stars1' src={Images.stars} />
							<img id='stars2' src={Images.stars} />
						</div>
						<img id='moon' src={Images.moon} style={{ translateX: isScheduleOrCOCOrCommunitiesActive ? '-150px' : '-1500px'}}  />
						<img id='christ' src={Images.christ2} />
					</div>
				</div>
				{activeSection === 'Inicio' && (
					<div className='sectionInicio'>
						<div className='Intro'>
							<div className='contentLogo'>
								<img src={Images.pyconboLogoText} alt="PYCON BOLIVIA 24" />
								<div className='contentDescription'>
									<a id="city">
										<img src={Icons.logoLocations} alt="" />
										<span>Cochabamba</span>
									</a>
									{/* <a>
										<img src={Icons.logoBuilding} alt="" />
										<span>Salón "La Mansión"</span>
									</a> */}
									<a>
										<img src={Icons.logoCalendar} alt="" />
										<span>Diciembre</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				)}
				{activeSection === 'Cronograma' && (
					<div className='sectionInicio' id='Schedule' >
						<h2>Cronograma</h2>
					</div>
				)}
				{activeSection === 'COC' && (
					<div className='sectionInicio' id='COC'>
						<h2>Código de Conducta</h2>
					</div>
				)}
				{activeSection === 'Comunidades' && (
					<div className='sectionInicio' id='Communities'>
						<h2>Comunidades</h2>
					</div>
				)}
				{/* {
					activeSection === 'Sponsors' && (
						<div className='sectionInicio' id='Sponsors'>
							<h2>Sponsors</h2>
						</div>
					)
				} */}
			</div>
			<div id='sectionsContainer'>
				{activeSection === 'Inicio' && (  
					<>
						<section className="IntroContent">
							<div className='contentBody'>
								<h2>PyCon Bolivia</h2>
								<div className='EventDescription'>
									<img className='logoSnake' src={Images.pyconlogo} />
									<div className='information'>
										<p>PyCon Bolivia es una conferencia anual dedicada al lenguaje de programación <span>Python</span>, dirigida a <span>desarrolladores, estudiantes, profesionales</span> de la tecnología y entusiastas de la programación.</p>
										<p>Presentando charlas técnicas, talleres prácticos y sesiones interactivas impartidas por expertos en la industria, tanto a <span>nivel nacional como internacional.</span></p>
										<div className='contentBody-tickets'>
											<p>Obtén tu entrada:</p>
											<a href="https://docs.google.com/forms/d/e/1FAIpQLSfakWhf2sF-BrSPGM7hEVruB_uvOVkg6s2aYEoAYMsugpMd1g/viewform" target="_blank">
												<div className='button'>Numpy</div>
											</a>
											<a href="https://docs.google.com/forms/d/e/1FAIpQLSejm44dXWd8UPV3JlJ6mdlQbotz4baQp44-k6yH6o7ERj0biA/viewform" target="_blank">
												<div className='button'>Django</div>
											</a>
										</div>
									</div>
								</div>
							</div>
							<Countdown targetDate={targetDate} />
							<CallForSponsor/>
							{/* <Keynotes /> */}
							{/* <Campus /> */}
							{/* <Sponsors /> */}

						</section>
					</>
				)}
				
				{activeSection === 'Cronograma' && (
					<>
						<section className="IntroContent">
							<Schedule />
						</section>
					</>
				)} 
				{activeSection === 'COC' && (
					<>
						<section className="IntroContent">
							<CodeOfConduct />
						</section>
					</>
				)} 
				{activeSection === 'Comunidades' && (
					<>
						<section className="IntroContent">
							<Communities />
						</section>
					</>
				)}
				{/* {
					activeSection === 'Sponsors' && (
						<>
							<section className="IntroContent">
								<Sponsors />
							</section>
						</>
					)
				} */}
			</div>
			<Footer setActiveSection={setActiveSection} />
		</div>
	);
};

export default Home;
